import { ConfigStateService, CurrentUserDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private redirectUrlKey: string = 'redirectUrl';

	constructor(private _config: ConfigStateService) {}

	getCurrentUser(): Observable<CurrentUserDto> {
		return this._config.getOne$('currentUser');
	}

	set redirectUrl(url: string) {
		localStorage.setItem(this.redirectUrlKey, url);
	}

	get redirectUrl() {
		return localStorage.getItem(this.redirectUrlKey);
	}

	clearRedirectUrl() {
		localStorage.removeItem(this.redirectUrlKey);
	}
}
